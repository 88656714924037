// SessionContext.jsx
import React, {createContext, useState, useEffect, useContext} from "react";

const SessionContext = createContext();

export const SessionProvider = ({children}) => {
  const [userInfo, setUserInfo] = useState(() => {
    const storedUser = sessionStorage.getItem("userData");
    const parsed = storedUser ? JSON.parse(storedUser) : [];
    return Array.isArray(parsed) ? parsed : [parsed];
  });

  useEffect(() => {
    if (userInfo.length > 0) {
      sessionStorage.setItem("userData", JSON.stringify(userInfo));
    } else {
      sessionStorage.removeItem("userData");
    }
  }, [userInfo]);

  const handleLogin = (userData) => {
    setUserInfo((prev) => [...prev, userData]);
  };

  const handleLogout = (user_uuid) => {
    setUserInfo((prev) => prev.filter((user) => user.user_uuid !== user_uuid));
  };

  return (
    <SessionContext.Provider value={{userInfo, handleLogin, handleLogout}}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
