import "./PrivacyPolicyComponent.css";

export default function PrivacyPolicyComponent() {
  return (
    <div className="privacy-component">
      <div className="privacy-container text glass-effect">
        <div className="privacy-menu">
          <a className="privacy-link" href="/terms-and-conditions">
            <h3>Terms & Conds</h3>
          </a>
          <h1 className="colored">Privacy Policy</h1>
          <a className="privacy-link" href="/cookie-policy">
            <h3>Cookie Policy</h3>
          </a>
        </div>
        <span className="privacy-main-text">
          <p>
            <u className="colored">Ultimo aggiornamento:</u> 04-12-2024
            <br />
            <u className="colored">Responsabile:</u> Danilo Bonomo
            <br />
            <u className="colored">Email:</u> accessoitaliano@gmail.com <br />
            <br />
            <br />
          </p>
        </span>
        <h3 style={{textAlign: "center", marginBottom: "5%"}}>
          <i>
            La presente Privacy Policy descrive come Accesso Italiano raccoglie,
            utilizza e protegge i dati personali degli utenti, in conformita' al
            Regolamento (UE) 2016/679 (GDPR).
          </i>
        </h3>
        <p
          className="
          secondary-text">
          <u className="colored ">1. Tipologia di dati raccolti:</u> Accesso
          Italiano raccoglie i seguenti dati personali: <br />
          <br /> <span className="subtitle">- Dati identificativi:</span> nome,
          cognome, email, e altri dati forniti per accedere ai servizi (es.
          iscrizione a newsletter o streaming).
          <br />
          <br /> <span className="subtitle">- Dati tecnici:</span> dati di
          sessione per il funzionamento tecnico dei servizi.
        </p>
        <p
          className="
          secondary-text">
          <u className="colored ">
            2. Finalita' del trattamento e base giuridica:
          </u>{" "}
          I dati personali sono trattati per erogare servizi richiesti dagli
          utenti, inclusi streaming e iscrizione a newsletter oltre a garantire
          il funzionamento tecnico della piattaforma. <br />
        </p>
        <p>
          <span className="emphasis colored">
            Accesso Italiano non utilizza dati per finalita' di marketing o
            pubblicita' esterne.
          </span>
        </p>
        <p className="secondary-text">
          <u className="colored "> 3. Modalita' di trattamento e sicurezza:</u>{" "}
          I dati sono salvati in database forniti da Amazon Web Services (AWS),
          una piattaforma conforme agli standard di sicurezza GDPR, con backup
          fisici gestiti tramite AWS e server virtuali. <br />
          <br />
          - Solamente cookie tecnici necessari al funzionamento del sito e dei
          servizi. <br />
          <br />- I log non vengono conservati oltre il tempo strettamente
          necessario.
        </p>
        <p className="secondary-text">
          <u className="colored ">4. Conservazione dei dati:</u> I dati vengono
          conservati per il tempo necessario a fornire i servizi richiesti.
          Quando non sono più necessari, i dati saranno cancellati in modo
          sicuro.
        </p>
        <p className="secondary-text">
          <u className="colored "> 5. Diritti degli utenti:</u> Gli utenti hanno
          diritto ad accedere ai propri dati e chiedere la rettifica o
          cancellazione.
          <br />
          Eventualmente opporsi al trattamento per motivi legittimi e si puó
          esercitare il diritto alla portabilità.
          <br />
          <br />
          Per esercitare i diritti, contattare: <u>accessoitaliano@gmail.com</u>
        </p>
        <p className="secondary-text">
          <u className="colored ">6. Trasferimento dei dati:</u> I dati
          potrebbero essere trasferiti al di fuori dell’UE (es. nei server AWS),
          ma saranno sempre protetti da garanzie conformi al GDPR. <br />
          <br />
        </p>
        <p className="secondary-text">
          <u className="colored ">8. Modifiche alla Privacy:</u>Policy Accesso
          Italiano si riserva il diritto di aggiornare la presente policy. Gli
          utenti saranno informati in caso di modifiche sostanziali.
        </p>
      </div>
    </div>
  );
}
