import "./ClientManagment.css";

export default function ClientManagment({setSelectedSection, selectedSection}) {
  return (
    <>
      <div className="client-managment-nav">
        {["Info", "Scheduled"].map((section) => (
          <div
            key={section}
            className={`client-managment-element text  ${
              selectedSection === section ? "active" : ""
            }`}
            onClick={() => setSelectedSection(section)}>
            <h2>{section}</h2>
          </div>
        ))}
      </div>
      <div className="client-managment-button text">
        <h2>New</h2>
      </div>
    </>
  );
}
