/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import "./HeaderComponent.css";
import logo from "./logo.png";
import {useNavigate} from "react-router-dom";

export default function HeaderComponent() {
  const navigate = useNavigate();

  function goToHome() {
    navigate("/");
  }

  return (
    <div className="header shadow-drop-center">
      <div className="text-1">
        <a className="text">Accesso</a>
      </div>
      <div className="logo">
        <a onClick={goToHome}>
          <img src={logo} width="90" height="100" />
        </a>
      </div>
      <div className="text-2">
        <a className="text">Italiano </a>
      </div>
    </div>
  );
}
