import "./App.css";
import AccessoItalianoApp from "./main/AccessoItaliano";
import BackgroundComponent from "./main/components/core/background-component/BackgroundComponent";
import FooterComponent from "./main/components/core/footer-component/FooterComponent";
import HeaderComponent from "./main/components/core/header-component/HeaderComponent";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <BackgroundComponent />
        <HeaderComponent />
        <div className="app-content">
          <AccessoItalianoApp />
        </div>
        <FooterComponent />
      </div>
    </BrowserRouter>
  );
}

export default App;
