import profile from "./profile.png";
import exit from "./exit.svg";
import gear from "./gear.svg";

export default function UserInfo() {
  return (
    <div className="home-component__user-info-border shadow-drop-bl">
      <div className="home-component__user-info-inner shadow-inset-center-white">
        <div className="profile-container">
          <img src={profile} alt="Foto profilo" />
        </div>
        <div className="home-component__button-container">
          <img src={gear} alt="Options" className="pulsate cursor-pointer" />
          <span style={{marginBottom: "22.5%"}} />
          <img src={exit} alt="Exit" className="pulsate cursor-pointer" />
        </div>
      </div>
    </div>
  );
}
