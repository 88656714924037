import "./PieChart.css";
import {useEffect, useState} from "react";

export default function PieChart({
  successfulSchedules,
  failedSchedules,
  totalSchedule,
}) {
  // Calcolo percentuale per il grafico
  const successfulPercentage = (successfulSchedules / totalSchedule) * 100;
  const failedPercentage = (failedSchedules / totalSchedule) * 100;
  const remainingPercentage = 100 - successfulPercentage - failedPercentage;
  const executed = successfulSchedules + failedSchedules;
  const [animatedPercentage, setAnimatedPercentage] = useState({
    success: 0,
    failed: 0,
    remaining: 0,
  });

  useEffect(() => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 3.8; // Incremento progressivo
      if (progress >= 100) {
        setAnimatedPercentage({
          success: successfulPercentage,
          failed: failedPercentage,
          remaining: remainingPercentage,
        });
        clearInterval(interval);
      } else {
        setAnimatedPercentage({
          success: (successfulPercentage * progress) / 100,
          failed: (failedPercentage * progress) / 100,
          remaining: (remainingPercentage * progress) / 100,
        });
      }
    }, 20);
    return () => clearInterval(interval);
  }, [successfulPercentage, failedPercentage, remainingPercentage]);

  return (
    <div className="pie-chart">
      <div className="pie-chart-wrapper">
        <div
          className="pie-chart"
          style={{
            background: `conic-gradient(
                #71b7f0 ${animatedPercentage.success}%, 
                #ff2d2d ${animatedPercentage.success}% ${
              animatedPercentage.success + animatedPercentage.failed
            }%, 
                gray ${
                  animatedPercentage.success + animatedPercentage.failed
                }% 100%
              )`,
          }}></div>
        <div className="pie-chart-center text">
          Total
          <br />
          {totalSchedule}
        </div>
      </div>
      <div className="legend">
        <div>
          <span className="legend-success"></span> Success:{" "}
          {successfulSchedules}
        </div>
        <div>
          <span className="legend-failed"></span> Failures: {failedSchedules}
        </div>
        <div>
          <span className="legend-pending"></span> Peending:{" "}
          {totalSchedule - executed}
        </div>
      </div>
    </div>
  );
}
