import "./FooterComponent.css";
import {ReactComponent as DocsIcon} from "./docs.svg";
import {useNavigate} from "react-router-dom";

export default function FooterComponent() {
  const navigate = useNavigate();
  return (
    <div className="footer shadow-drop-center text">
      &copy; 2024 – All rights reserved
      <div
        className="footer-button pulsate-bck"
        onClick={() => navigate("/privacy-policy")}>
        <DocsIcon />
      </div>
    </div>
  );
}
