import {useState, useEffect} from "react";
import "./HomeComponent.css";
import UserInfo from "../fragments/user-info/UserInfo";
import plus from "./plus.svg";
import ExternalLoginComponent from "../core/ext-login-component/ExternalLoginComponent";
import LoaderComponent from "../fragments/loader/LoaderComponent";
import {useSession} from "../core/SessionProvider";
import ClientManagment from "../fragments/client-managment/ClientManagment";
import ClientInfo from "../fragments/client-info/ClientInfo";
import ScheduledList from "../fragments/scheduled-list/ScheduledList";
import {AnimatePresence, motion} from "framer-motion";
import scheduled from "../../assets/data/ScheduledComplete.json";

export default function HomeComponent() {
  const [isOpen, setIsOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {userInfo, handleLogin} = useSession();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isSelected, setSelected] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");
  
  useEffect(() => {
    setAnimate(isOpen);
  }, [isOpen]);

  const handleSelect = (index) => {
    setSelected(!isSelected);
    setSelectedSection("");
    setSelectedIndex((prevIndex) => (prevIndex === index ? "" : index));
  };

  return (
    <div className="home-component shadow-drop-center">
      <div className="home-component__border ">
        <div className="home-component__left-board shadow-inset-center">
          {userInfo.length === 0 ? (
            <div className="row first-row">
              <img
                src={plus}
                alt="Add"
                className="pulsate"
                onClick={() => setIsOpen(true)}
              />
            </div>
          ) : (
            <>
              {userInfo &&
                userInfo.length > 0 &&
                userInfo.map((user, index) => (
                  <div
                    key={`user-${index}`}
                    className={`row-filled ${index === 0 ? "first-row" : ""} ${
                      selectedIndex === index ? "selected-row" : ""
                    }`}
                    onClick={() => handleSelect(index)}>
                    <span
                      className={`text ${
                        selectedIndex === index ? "selected-text" : ""
                      }`}>
                      {user.name} {user.surname}
                    </span>
                  </div>
                ))}
              <div key="add" className="row">
                <img
                  src={plus}
                  alt="Add"
                  className="pulsate cursor-pointer"
                  onClick={() => setIsOpen(true)}
                />
              </div>
            </>
          )}
        </div>
        <span className="home-component__separator " />
        <div className="home-component__right-board shadow-inset-center  glass-effect">
          <div className="toolbar">
            <UserInfo />
            <AnimatePresence>
              {isSelected && (
                <motion.div
                  initial={{x: "-55%", opacity: 0}}
                  animate={{x: "0%", opacity: 1}}
                  exit={{x: "-55%", opacity: 0}}
                  transition={{duration: 0.5}}
                  className="client-managment-container shadow-drop-br">
                  <ClientManagment
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <span className="home-component__separator " />
          {/* Nuovo contenitore per la sezione selezionata */}
          <div className="client-managment-content ">
            {selectedSection === "Info" && (
              <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.4}}>
                <ClientInfo />
              </motion.div>
            )}

            {selectedSection === "Scheduled" && (
              <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.4}}>
                <ScheduledList scheduled={scheduled} />
              </motion.div>
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className="login-modal"
          onClick={(e) => {
            if (e.target.classList.contains("menu-overlay")) {
              setIsOpen(false);
            }
          }}>
          <div className={`menu-overlay ${animate ? "menu-open" : ""}`}>
            {isLoading ? (
              <LoaderComponent />
            ) : (
              <div className="login-modal-content">
                <div className="login-internal">
                  <ExternalLoginComponent
                    external={"Tik-Tok"}
                    setIsOpen={setIsOpen}
                    setIsLoading={setIsLoading}
                    handleLogin={handleLogin}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
