import "./ScheduledList.css";
import ScheduledCard from "../schedule-card/ScheduledCard"

const ScheduledHeader = () => (
  <div className="scheduled-card text" style={{color: "#71b7f0"}}>
    <div className="scheduled-header" style={{width: "35%"}}>
      Titolo
    </div>
    <div className="scheduled-header" style={{width: "15%"}}>
      Data
    </div>
    <div className="scheduled-header">Tags</div>
    <div className="scheduled-header" style={{width: "15%"}}>
      Status
    </div>
  </div>
);

export default function ScheduledList({scheduled}) {
  // Filtra i video con status "Completed", "Error" o "Pending"
  const filteredVideos = scheduled.filter(
    (video) =>
      video.status === "Completed" ||
      video.status === "Error" ||
      video.status === "Pending"
  );

  // Se non ci sono video da mostrare, non renderizza nulla
  if (filteredVideos.length === 0) {
    return null;
  }

  return (
    <div className="scheduled-list">
      <ScheduledHeader />
      {filteredVideos.map((video, index) => (
        <ScheduledCard key={index} video={video} />
      ))}
    </div>
  );
}
