import "./ScheduledCard.css";
import { useState } from "react";
import pencil from "./pencil.svg";
import pencilBlue from "./pencil-blue.svg";
import ScheduledModal from "../schedule-modal/ScheduleModal";

const ScheduledCard = ({ video }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { title, scheduledPublication, tags, status } = video;
  const formattedDate = new Date(scheduledPublication).toLocaleString();
  const [iconSrc, setIconSrc] = useState(pencil);

  let statusContent = null;
  if (status === "Completed") {
    statusContent = <span style={{ color: "#2cbe47" }}>{status}</span>;
  } else if (status === "Error") {
    statusContent = <span style={{ color: "#ff2d2d" }}>{status}</span>;
  } else if (status === "Pending") {
    statusContent = (
      <>
        <img
            src={iconSrc}
            className="pencil-img pulsate"
            alt="Pencil icon"
            onClick={() => setIsModalOpen(true)}
            onMouseEnter={() => setIconSrc(pencilBlue)} // Cambia l'icona quando il mouse entra
            onMouseLeave={() => setIconSrc(pencil)} // Ripristina l'icona originale quando il mouse esce
        />
        <span>{status}</span>
        {isModalOpen && <ScheduledModal onClose={() => setIsModalOpen(false)} />}
      </>
    );
  }

  return (
    <div className={`scheduled-card text `}>
      <div className={`scheduled-row ${isModalOpen ? "no-hover" : ""}`}>
        <div className="scheduled-field" style={{ width: "35%" }}>
          {title}
        </div>
        <div className="scheduled-field" style={{ width: "15%" }}>
          {formattedDate}
        </div>
        <div className="scheduled-field">{tags.join(", ")}</div>
        <div
          className="scheduled-field"
          style={{
            width: "15%",
            justifyContent: "center",
            flexDirection: "row-reverse",
          }}
        >
          {statusContent}
        </div>
      </div>
    </div>
  );
};

export default ScheduledCard;
