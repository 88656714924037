import "./ClientInfo.css";
import client from "../../../assets/data/ClientData.json";
import FormInfo from "../form-info/FormInfo";
import DataInfo from "../data-info/DataInfo";
import PieChart from "../pie-chart/PieChart";

export default function ClientInfo() {
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("en-EN", options);
  };
  return (
    <div className="client-info-container">
      <div className="client-info-data">
        <FormInfo label="Username" value={client.username} />
        <FormInfo label="User UUID" value={client.user_uuid} />
        <FormInfo label="Email" value={client.email} />
        <FormInfo label="Name" value={client.name} />
        <FormInfo label="Surname" value={client.surname} />
        <FormInfo label="Tag" value={client.tag} />
      </div>
      <div className="data-container">
        <div className="data-container__info">
          <PieChart
            successfulSchedules={client.total_succesful_schedules}
            failedSchedules={client.total_failed_schedules}
            totalSchedule={client.total_schedules}
          />

          <div className="data-container__data">
            <DataInfo
              label="Last succesful schedule"
              value={formatDate(client.last_succesful_schedule)}
            />
            <DataInfo
              label="Next schedule"
              value={formatDate(client.next_schedule)}
            />
          </div>
          <div className="data-container__buttons">
            <div className="data-container__button">
              <button className="client-logout text ">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
