import "./DataInfo.css";

export default function DataInfo({label, value}) {
  return (
    <div className="data-info-box">
      <label className="data-info-label text">{label}</label>
      <div className="data-info-text">{value} </div>
    </div>
  );
}
