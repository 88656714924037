import "./FormInfo.css";

export default function FormInfo({label, value}) {
  return (
    <div className="form-info-box">
      <label className="form-info-label text">{label}</label>
      <p className="form-info-text">{value} </p>
    </div>
  );
}
