import "./LoaderComponent.css";

export default function LoaderComponent() {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <div className="loader"></div>
        <div className="loader-inner"></div>
      </div>
    </div>
  );
}
