import "./TermsComponent.css";

export default function TermsComponent() {
  return (
    <div className="terms-component">
      <div className="terms-container text glass-effect">
        <div className="privacy-menu">
          <a className="privacy-link" href="/privacy-policy">
            <h3>Privacy Policy</h3>
          </a>
          <h1 className="colored">Terms & Conds</h1>
          <a className="privacy-link" href="/cookie-policy">
            <h3>Cookie Policy</h3>
          </a>
        </div>
        <h3 style={{textAlign: "center", marginTop: "5%"}}>
          <i>
            L'accesso e l'utilizzo dei servizi di Accesso Italiano implicano
            l'accettazione dei presenti Termini e Condizioni
          </i>
        </h3>
        <p
          className="
          secondary-text">
          <u className="colored ">1. Servizi Offerti:</u> Accesso Italiano offre
          servizi di streaming, newsletter e altri contenuti digitali senza
          pubblicita'
        </p>
        <p
          className="
          secondary-text">
          <u className="colored ">2. Obblighi dell'utente:</u> <br />
          <br /> <span className="subtitle">- Fornire dati veritieri </span>al
          momento della registrazione. <br />
          <br />
          <span className="subtitle">
            - Non utilizzare i servizi per attivita' illegali o non autorizzate
          </span>
        </p>
        <p
          className="
          secondary-text">
          <u className="colored ">3. Responsabilita':</u> Accesso Italiano non
          sara' responsabile per eventuali interruzioni del servizio o perdite
          di dati
        </p>
        <p
          className="
          secondary-text">
          <u className="colored ">4. Modifiche ai Termini:</u> Accesso Italiano
          si riserva il diritto di modificare i Termini, notificando gli utenti
          in anticipo
        </p>
      </div>
    </div>
  );
}
