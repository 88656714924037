import {useState} from "react";
import "./ExternalLoginComponent.css";
import {ReactComponent as LowVisionIcon} from "../../../assets/images/low-vision.svg";
import userData from "../../../assets/data/UserData.json";

export default function ExternalLoginComponent({
  external,
  setIsOpen,
  setIsLoading,
  handleLogin,
}) {
  const [username, setUsername] = useState(""); // FIX: valore iniziale stringa
  const [password, setPassword] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  function handleUsernameChange(event) {
    setShowErrorMessage(false);
    setUsername(event.target.value);
  }

  function handlePasswordChange(event) {
    setShowErrorMessage(false);
    setPassword(event.target.value);
  }

  function handleSubmit() {
    if (username === userData.username) {
      setIsLoading(true); // Mostra il loader
      setShowErrorMessage(false);
      setTimeout(() => {
        setIsLoading(false); // Nasconde il loader dopo 2s
        setIsOpen(false); // Chiude il modale
        setShowSuccessMessage(true);
      }, 4000);
      handleLogin(userData);
      localStorage.setItem("tikTokAuthToken", "test");
    } else {
      setShowSuccessMessage(false);
      setShowErrorMessage(true);
    }
  }

  function togglePasswordVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <div>
      <div className="ext-login-component">
        <h2 className="ext-login-title text">Accedi a {external}</h2>
        <div className="ext-login-container text">
          {showSuccessMessage && (
            <div className="success-message">Authenticated successfully</div>
          )}
          {showErrorMessage && (
            <div className="error-message">Credenziali non valide</div>
          )}
          <div className="login-form">
            <div>
              <label className="login-label">Username</label>
              <br />
              <input
                type="text"
                name="username"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div>
              <label className="login-label">Password</label>
              <br />
              <div className="password-wrapper">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="password-input"
                />
                <button
                  type="button"
                  className="toggle-password-btn"
                  onClick={togglePasswordVisibility}>
                  <LowVisionIcon />
                </button>
              </div>
            </div>
            <div>
              <button
                className="ext-login-button neomorphism-button"
                onClick={handleSubmit}>
                Accedi
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
