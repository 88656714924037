import "./RecoveryComponent.css";
import {useState} from "react";

export default function RecoveryComponent() {
  const [recoveryType, setRecoveryType] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  const handleRecoveryTypeChange = (e) => {
    setRecoveryType(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (recoveryType === "username") {
      console.log("Recupero username tramite email:", email);
    } else if (recoveryType === "password") {
      console.log("Recupero password tramite username:", username);
    }
  };

  return (
    <div className="recovery-component">
      <div className="recovery-container shadow-drop-center text glass-effect">
        {/* Form per il recupero */}
        <form onSubmit={handleSubmit}>
          <label className="recovery-label">
            Recupera <br />
            <select
              value={recoveryType}
              onChange={handleRecoveryTypeChange}
              className="neomorphism-button">
              <option value="">-- Seleziona --</option>
              <option value="username">-- Username --</option>
              <option value="password">-- Password --</option>
            </select>
          </label>

          {/* Campo email per il recupero dell'username */}
          {recoveryType === "username" && (
            <div className="input-container">
              <label className="recovery-label">
                Inserisci l'email
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="neomorphism-input"
                />
              </label>
            </div>
          )}

          {/* Campo password per il recupero della password */}
          {recoveryType === "password" && (
            <div className="input-container">
              <label className="recovery-label">
                Inserisci l'username
                <input
                  type="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  className="neomorphism-input"
                />
              </label>
            </div>
          )}
          <div>
            <button
              type="submit"
              className="recovery-button neomorphism-button">
              Invia
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
