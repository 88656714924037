import "./BackgroundComponent.css";

export default function BackgroundComponent() {
  return (
    <div id="hex-grid">
      <div className="light"></div>
      <div className="grid"></div>
    </div>
  );
}
