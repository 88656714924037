import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./ScheduleModal.css";

const ScheduledModal = ({ onClose }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
        document.body.style.pointerEvents = "none"; // Disabilita interazioni con il background
        document.body.style.overflow = "hidden"; // Previene lo scroll
        document.body.classList.add("no-hover"); // Disabilita hover sul background

        return () => {
            document.body.style.pointerEvents = "auto"; // Ripristina interazioni con il background
            document.body.style.overflow = "auto"; // Ripristina lo scroll
            document.body.classList.remove("no-hover"); // Riattiva hover sul background
        };
    }, []);

    const handleClose = () => {
        setAnimate(false);
        setTimeout(onClose, 300); // Attendi la fine dell'animazione prima di chiudere
    };

    return ReactDOM.createPortal(
        <div className="overlay-container">
            <div className={`menu-overlay-schedule ${animate ? "menu-open" : ""}`} onClick={handleClose}>
                <div className="bg-white p-6 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
                    <p>Questa è la modale!</p>
                    <button className="mt-4 px-4 py-2 bg-red-500 text-white rounded" onClick={handleClose}>
                        Chiudi
                    </button>
                </div>
            </div>
        </div>,
        document.body // Monta la modale direttamente nel body
    );
};

export default ScheduledModal;
