import {Routes, Route, Navigate} from "react-router-dom";
import LoginComponent from "./components/core/login-component/LoginComponent";
import RecoveryComponent from "./components/core/recovery-component/RecoveryComponent";
import TermsComponent from "./components/core/terms-component/TermsComponent";
import HomeComponent from "./components/home-component/HomeComponent";
import ErrorComponent from "./components/core/error-component/ErrorComponent";
import "./AccessoItaliano.css";
import PrivacyPolicyComponent from "./components/core/privacy-component/PrivacyPolicyComponent";
import CookieComponent from "./components/core/cookie-component/CookieComponent";
import {SessionProvider} from "./components/core/SessionProvider";

export default function AccessoItalianoApp() {
  const isAuthenticated = localStorage.getItem("authToken"); // Logica di autenticazione

  return (
    <div className="accesso-italiano-app">
      <Routes>
        <Route path="/" element={<LoginComponent />} />
        <Route path="/recovery" element={<RecoveryComponent />} />

        <Route
          path="/home"
          element={
            isAuthenticated === "test" ? (
              <SessionProvider>
                <HomeComponent />
              </SessionProvider>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/error" element={<ErrorComponent />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyComponent />} />
        <Route path="/cookie-policy" element={<CookieComponent />} />
        <Route path="/terms-and-conditions" element={<TermsComponent />} />
      </Routes>
    </div>
  );
}
